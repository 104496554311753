export const isDevNextAuthEnvironment = () => {
  if (process.env.NEXT_PUBLIC_AUTH_PROVIDER === "cognito") {
    return false;
  }
  if (process.env.NEXT_PUBLIC_AUTH_PROVIDER === "fake") {
    return true;
  }
  if (process.env.NODE_ENV !== "production") return true;

  if (process.env.NEXT_PUBLIC_ENVIRONMENT_ID === "new") {
    return false;
  }

  return process.env.VERCEL_ENV === "preview";
};

export const getSignProvider = () => {
  if (isDevNextAuthEnvironment()) {
    return undefined;
  }

  return "cognito";
};
