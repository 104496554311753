"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@irbano/react-components/dist/components/ui/button";
import { Checkbox } from "@irbano/react-components/dist/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@irbano/react-components/dist/components/ui/form";
import { Input } from "@irbano/react-components/dist/components/ui/input";
import { useToast } from "@irbano/react-components/dist/components/ui/toast";
import { cn } from "@irbano/react-components/dist/lib/utils";
import { signIn } from "next-auth/react";
import {
  useSearchParams,
  useRouter,
  ReadonlyURLSearchParams,
} from "next/navigation";

import * as React from "react";
import { useForm } from "react-hook-form";
import { TranslationProp, useTranslation } from "shared/hooks/useTranslation";
import { isDevNextAuthEnvironment } from "utils/nextAuth";
import { z } from "zod";
import { OtherLoginStrategies } from "../components/other-login-strategies";

export type SignInFormTranslations = {
  auth: {
    inputs: {
      email: {
        label: string;
        placeholder: string;
      };
      password: {
        label: string;
        placeholder: string;
      };
    };
    signIn: {
      errors: {
        notAuthorized: string;
        passwordResetRequired: string;
      };
      signInButton: string;
      orContinueWith: string;
    };
  };
};

export type SignInFormProps = {
  translation: TranslationProp<SignInFormTranslations>;
  callbackUrl?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const signInSchema = z.object({
  email: z.string().email(),
  password: z.string(),
  isAdmin: z.boolean().optional(),
  plan: z.string().optional(),
});

export const SignInForm = ({
  className,
  translation,
  callbackUrl,
  ...props
}: SignInFormProps) => {
  const { t } = useTranslation({ translation });
  const searchParams = useSearchParams();
  const router = useRouter();
  const { toast } = useToast();

  const parsedCallbackUrl = callbackUrl || "/app";

  async function onSubmit(values: z.infer<typeof signInSchema>) {
    console.debug({
      values,
      input: {
        ...values,
        redirect: false,
      },
    });

    const result = await signIn("cognito-credentials", {
      ...values,
      redirect: false,
    });
    console.debug("Error", result);

    if (result?.ok) {
      return router.replace(parsedCallbackUrl);
    }

    if (
      result?.error === "NotAuthorized" ||
      result?.error === "CredentialsSignin"
    ) {
      return toast({
        title: t("auth.signIn.errors.notAuthorized"),
        variant: "destructive",
      });
    }
    if (result?.error === "PasswordResetRequired") {
      toast({
        title: t("auth.signIn.errors.passwordResetRequired"),
        variant: "default",
      });

      const params = new URLSearchParams(
        searchParams as ReadonlyURLSearchParams
      );

      params.set("email", values.email);
      return router.push(`/auth/password-reset?${params.toString()}`);
    }
  }

  const form = useForm<z.infer<typeof signInSchema>>({
    resolver: zodResolver(signInSchema),
    defaultValues: {
      email: "",
      password: "",
      isAdmin: false,
      plan: "",
    },
  });

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("auth.inputs.email.label")}</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    placeholder={t("auth.inputs.email.placeholder")}
                    autoComplete="username"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="mt-2">
                <FormLabel>{t("auth.inputs.password.label")}</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder={t("auth.inputs.password.placeholder")}
                    autoComplete="current-password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {isDevNextAuthEnvironment() ? (
            <>
              <FormField
                control={form.control}
                name="plan"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Plan</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="isAdmin"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4 shadow">
                    <FormControl>
                      <Checkbox
                        name="isAdmin"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>Is Admin</FormLabel>
                    </div>
                  </FormItem>
                )}
              />
            </>
          ) : null}

          <Button
            type="submit"
            className="w-full"
            isLoading={form.formState.isSubmitting}
          >
            {t("auth.signIn.signInButton")}
          </Button>

          <OtherLoginStrategies
            callbackUrl={parsedCallbackUrl}
            translations={{
              orContinueWithText: t("auth.signIn.orContinueWith"),
            }}
          />
        </form>
      </Form>
    </div>
  );
};
